import { UserSchema } from '../schemas/user-schema';
import { Serializable } from './Serializable';

export class User extends Serializable {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public emailAddress?: string,
    public phoneNumber?: string,
    public role?: string,
    public has_active_subscription?: boolean,
    public trial_eligible?: boolean,
    public acceptedconditions?: boolean
  ) {
    super();
  }

  override serialize(endpoint: string): any {
    return {
      firstname: this.firstName,
      lastname: this.lastName,
      email: this.emailAddress,
      phonenumber: this.phoneNumber,
      role: this.role,
      acceptedconditions: this.acceptedconditions,
    };
  }

  override deserialize(endpoint: string, accountDetails: UserSchema) {
    this.firstName = accountDetails.firstname;
    this.lastName = accountDetails.lastname;
    this.emailAddress = accountDetails.email;
    this.phoneNumber = accountDetails.phonenumber;
    this.role = accountDetails.role;
    this.trial_eligible = accountDetails.trial_eligible;
    this.has_active_subscription = accountDetails.has_active_subscription;
    this.role = accountDetails.role;
    this.acceptedconditions = accountDetails.acceptedconditions;

    return this;
  }
}
